<template>
    <div class="container-xxl flex-grow-1 container-p-y">
        <form class="row" @submit="submitForm" v-if="!isLoading">
            <div class="col-12 col-md-8">
                <div class="card mb-3">
                    <div class="card-header">
                        <h5 class="f-cap" v-if="isEdit == 0">
                            {{ $t('client.header.create') }}
                        </h5>
                        <h5 class="f-cap" v-if="isEdit == 1">
                            {{ $t('client.header.edit') }} {{ header.client_name }}</h5>
                    </div>
                    <div class="card-body">
                        <div class="form-group mb-2 row">
                            <div class="col-12 col-md-3">
                                <label class="form-label">
                                    {{ $t('client.client_nip') }}</label>
                            </div>
                            <div class="col-12 col-md-9">
                                <div class="input-group">
                                    <input type="text" class="form-control" v-model="item.client_nip"
                                        :class="{ 'is-invalid': validation.client_nip != '' }" s-mask="'###-###-##-##'" />
                                    <div class="input-group-append">
                                        <button :siabled="isLoadingGus" type="button" @click.prevent="pobierzGus"
                                            class="btn btn-icon btn-outline-primary" title="Pobierz z bazy GUS"
                                            v-tooltip>
                                            <i class="material-icons-outlined">search</i>
                                        </button>
                                    </div>
                                    <div class="invalid-feedback">{{ validation.client_nip }}</div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group mb-2 row">
                            <div class="col-12 col-md-3">
                                <label class="form-label">
                                    {{ $t('client.client_name_short') }}</label>
                            </div>
                            <div class="col-12 col-md-9">
                                <input type="text" class="form-control"
                                    :class="{ 'is-invalid': validation.client_name_short != '' }"
                                    v-model="item.client_name_short">
                                <div class="invalid-feedback">{{ validation.client_name_short }}</div>
                            </div>
                        </div>
                        <div class="form-group mb-2 row">
                            <div class="col-12 col-md-3">
                                <label class="form-label">
                                    {{ $t('client.client_name') }}</label>
                            </div>
                            <div class="col-12 col-md-9">
                                <input type="text" class="form-control"
                                    :class="{ 'is-invalid': validation.client_name != '' }" v-model="item.client_name">
                                <div class="invalid-feedback">{{ validation.client_name }}</div>
                            </div>
                        </div>
                        <!-- <div class="form-group mb-2 row">
                            <div class="col-12 col-md-3">
                                <label class="form-label">
                                    {{ $t('client.client_stage') }}</label>
                            </div>
                            <div class="col-12 col-md-9">
                                <select class="form-select" v-model="item.client_stage"
                                    :class="{ 'is-invalid': validation.client_stage != '' }">
                                    <option value=""></option>
                                    <option v-for="i,key in slownik_client_stage" :value="i.value" :key="key">{{ i.name }}
                                    </option>
                                </select>
                                <div class="invalid-feedback">{{ validation.client_stage }}</div>
                            </div>
                        </div> -->
                        <!-- <div class="form-group mb-2 row">
                            <div class="col-12 col-md-3">
                                <label class="form-label">
                                    {{ $t('client.client_group') }}</label>
                            </div>
                            <div class="col-12 col-md-9">
                                <select class="form-select" v-model="item.client_group"
                                    :class="{ 'is-invalid': validation.client_group != '' }">
                                    <option value=""></option>
                                    <option v-for="i,key in slownik_client_group" :value="i.value" :key="key">{{ i.name }}
                                    </option>
                                </select>
                                <div class="invalid-feedback">{{ validation.client_group }}</div>
                            </div>
                        </div> -->
                        <h4>
                            {{ $t('client.header.client_dane_ogolne') }}
                        </h4>
                        <div class="form-group mb-2 row">
                            <!-- <div class="col-12 col-md-3">
                                <label class="form-label">
                                    {{ $t('client.client_type') }}</label>
                            </div> -->
                            <div class="col-12 col-md-9">
                                <div v-for="i,key in slownik_client_type" :key="key">
                                    <b-form-checkbox @change="changeCheckbox" :value="true" v-model="g_client_type[i.value]"
                                        switch> {{ i.name }}</b-form-checkbox>
                                </div>
                                <div class="invalid-feedback">{{ validation.client_type }}</div>
                            </div>
                        </div>

                        <div class="form-group mb-2 row">
                            <div class="col-12 col-md-3">
                                <label class="form-label">
                                    {{ $t('client.client_user_id') }}</label>
                            </div>
                            <div class="col-12 col-md-9">
                                <select class="form-select" v-model="item.user_id"
                                    :class="{ 'is-invalid': validation.user_id != '' }">
                                    <option value=""></option>
                                    <option v-for="i,key in slownik_user" :value="i.user_id" :key="key">{{ i.name }}</option>
                                </select>
                                <div class="invalid-feedback">{{ validation.user_id }}</div>
                            </div>
                        </div>
                        <!-- <div class="form-group mb-2 row">
                            <div class="col-12 col-md-3">
                                <label class="form-label">
                                    {{ $t('client.client_economic_form') }}</label>
                            </div>
                            <div class="col-12 col-md-9">
                                <select class="form-select" v-model="item.client_economic_form" 
                                    :class="{ 'is-invalid': validation.client_economic_form != '' }">
                                    <option value=""></option>
                                    <option v-for="i,key in slownik_client_economic_form" :value="i.value" :key="key">{{ i.name }}
                                    </option>
                                </select>
                                <div class="invalid-feedback">{{ validation.client_economic_form }}</div>
                            </div>
                        </div> -->
                        <h4>
                            {{ $t('client.header.client_dane_info') }}
                        </h4>
                        <div class="form-group mb-2 row">
                            <div class="col-12 col-md-3">
                                <label class="form-label">
                                    {{ $t('client.client_regon') }}</label>
                            </div>
                            <div class="col-12 col-md-9">
                                <input type="text" class="form-control" v-model="item.client_regon"
                                    :class="{ 'is-invalid': validation.client_regon != '' }">
                                <div class="invalid-feedback">{{ validation.client_regon }}</div>
                            </div>
                        </div>
                        <div class="form-group mb-2 row">
                            <div class="col-12 col-md-3">
                                <label class="form-label">
                                    {{ $t('client.client_krs') }}</label>
                            </div>
                            <div class="col-12 col-md-9">
                                <input type="text" class="form-control" v-model="item.client_krs"
                                    :class="{ 'is-invalid': validation.client_krs != '' }">
                                <div class="invalid-feedback">{{ validation.client_krs }}</div>
                            </div>
                        </div>
                        <div class="form-group mb-2 row">
                            <div class="col-12 col-md-3">
                                <label class="form-label">
                                    {{ $t('client.client_wojewodztwo') }}</label>
                            </div>
                            <div class="col-12 col-md-9">
                                <select class="form-select" v-model="item.client_wojewodztwo"
                                    :class="{ 'is-invalid': validation.client_wojewodztwo != '' }">
                                    <option value=""></option>
                                    <option v-for="i,key in slownik_wojewodztwo" :value="i.value" :key="key">{{ i.name }}</option>
                                </select>
                                <div class="invalid-feedback">{{ validation.client_wojewodztwo }}</div>
                            </div>
                        </div>
                        <div class="form-group mb-2 row">
                            <div class="col-12 col-md-3">
                                <label class="form-label">
                                    {{ $t('client.client_postcode') }}</label>
                            </div>
                            <div class="col-12 col-md-9">
                                <div class="input-group">
                                    <input @input="pobierzPostCode" type="text" class="form-control" v-model="item.client_postcode"
                                        :class="{ 'is-invalid': validation.client_postcode != '' }" s-mask="'##-###'">
                                    <div class="input-group-append">
                                        <button type="button" @click.prevent="pobierzPostCode"
                                            class="btn btn-icon btn-outline-primary" title="Znajdź miasto"
                                            v-tooltip>
                                            <i class="material-icons-outlined">search</i>
                                        </button>
                                    </div>
                                    <div class="invalid-feedback">{{ validation.client_postcode }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mb-2 row">
                            <div class="col-12 col-md-3">
                                <label class="form-label">
                                    {{ $t('client.client_city') }}</label>
                            </div>
                            <div class="col-12 col-md-9">
                                <input type="text" class="form-control" v-model="item.client_city"
                                    :class="{ 'is-invalid': validation.client_city != '' }">
                                <div class="invalid-feedback">{{ validation.client_city }}</div>
                            </div>
                        </div>
                        <div class="form-group mb-2 row">
                            <div class="col-12 col-md-3">
                                <label class="form-label">
                                    {{ $t('client.client_address') }}</label>
                            </div>
                            <div class="col-12 col-md-9">
                                <input type="text" class="form-control" v-model="item.client_address"
                                    :class="{ 'is-invalid': validation.client_address != '' }">
                                <div class="invalid-feedback">{{ validation.client_address }}</div>
                            </div>
                        </div>
                        <div class="form-group mb-2 row">
                            <div class="col-12 col-md-3">
                                <label class="form-label">
                                    {{ $t('client.client_www') }}</label>
                            </div>
                            <div class="col-12 col-md-9">
                                <input type="text" class="form-control" v-model="item.client_www"
                                    :class="{ 'is-invalid': validation.client_www != '' }">
                                <div class="invalid-feedback">{{ validation.client_www }}</div>
                            </div>
                        </div>
                        <div class="form-group mb-2 row">
                            <!-- <div class="col-12 col-md-3">
                                <label class="form-label">
                                    {{ $t('client.client_want_to') }}</label>
                            </div> -->
                            <div class="col-12 col-md-9">
                                <div v-for="i,key in slownik_client_want_to" :key="key">
                                    <b-form-checkbox @change="changeCheckbox" :value="true"
                                        v-model="g_client_want_to[i.value]" switch> {{ i.name }}</b-form-checkbox>
                                </div>
                                <div class="invalid-feedback">{{ validation.client_want_to }}</div>
                            </div>
                        </div>
                        <div class="form-group mb-2 row">
                            <div class="col-12 col-md-3">
                                <label class="form-label">
                                    {{ $t('client.client_comment') }}</label>
                            </div>
                            <div class="col-12 col-md-9">
                                <textarea class="form-control" v-model="item.client_comment"
                                    :class="{ 'is-invalid': validation.client_comment != '' }"></textarea>
                                <div class="invalid-feedback">{{ validation.client_comment }}</div>
                            </div>
                        </div>

                        <!-- <vue-dropzone v-if="dropzoneOptions.url" class="mb-4" v-on:vdropzone-queue-complete="uploadSuccess"
                            v-on:vdropzone-sending="sendingEvent" ref="myVueDropzone" id="dropzone"
                            :options="dropzoneOptions" :use-custom-slot="true">
                            <div class="dropzone-custom-content">
                                <h5 class="dropzone-custom-title">Upuść plik tutaj</h5>
                                <h6 class="subtitle">...lub kliknij i wybierz plik ze swojego komputera</h6>
                            </div>
                        </vue-dropzone> -->

                        <div class="text-center mt-5">
                            <button type="submit" @click.prevent="submitForm" class="btn btn-outline-success me-2 f-cap">
                                {{ $t('client.button.save') }}</button>
                            <button @click.prevent="cancelForm" class="btn btn-outline-secondary f-cap">
                                {{ $t('client.button.cancel') }}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="card mb-3">
                    <div class="card-header d-flex justify-content-between align-items-center">
                        <h5>
                            {{ $t('client.header.client_dane_contact') }}
                        </h5>
                        <div class="card-header-right">
                            <button type="button" @click.prevent="clickPepresentativeAdd"
                                class="btn btn-outline-primary w-100">Dodaj przedstawiciela</button>
                        </div>
                    </div>
                </div>
                <div class="card mb-3" v-for="(i, index) in item.representative" :key="index">
                    <div class="card-header d-flex justify-content-between align-items-center">
                        <h5>
                            {{ $t('client.header.client_rep') }} #{{ index + 1 }}
                        </h5>
                        <div class="card-header-right">
                            <button type="button" @click.prevent="clickPepresentativeDelete(i)"
                                class="btn btn-icon btn-outline-secondary me-1" title="usuń"><i
                                    class="material-icons-outlined">delete</i></button>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="form-group mb-2 ">
                            <label class="form-label">
                                {{ $t('client.client_rep_name') }}</label>
                            <input type="text" class="form-control" v-model="i.client_rep_name"
                                :class="{ 'is-invalid': representative_validation[index].client_rep_name != '' }">
                            <div class="invalid-feedback">{{ representative_validation[index].client_rep_name }}</div>
                        </div>
                        <div class="form-group mb-2 ">
                            <label class="form-label">
                                {{ $t('client.client_rep_position') }}</label>
                            <input type="text" class="form-control" v-model="i.client_rep_position"
                                :class="{ 'is-invalid': representative_validation[index].client_rep_position != '' }">
                            <div class="invalid-feedback">{{ representative_validation[index].client_rep_position }}</div>
                        </div>
                        <div class="form-group mb-2 ">
                            <label class="form-label">
                                {{ $t('client.client_rep_phone') }}</label>
                            <input type="tel" class="form-control" v-model="i.client_rep_phone"
                                :class="{ 'is-invalid': representative_validation[index].client_rep_phone != '' }">
                            <div class="invalid-feedback">{{ representative_validation[index].client_rep_phone }}</div>
                        </div>
                        <div class="form-group mb-2 ">
                            <label class="form-label">
                                {{ $t('client.client_rep_email') }}</label>
                            <input type="email" class="form-control" v-model="i.client_rep_email"
                                :class="{ 'is-invalid': representative_validation[index].client_rep_email != '' }">
                            <div class="invalid-feedback">{{ representative_validation[index].client_rep_email }}</div>
                        </div>
                        <div class="form-group mb-2 ">
                            <label class="form-label">
                                {{ $t('client.client_rep_comment') }}</label>
                            <div class="input-group">
                                <textarea type="text" class="form-control" v-model="i.client_rep_comment"
                                    :class="{ 'is-invalid': representative_validation[index].client_rep_comment != '' }"></textarea>
                                <div class="invalid-feedback">{{ representative_validation[index].client_rep_comment }}
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <!-- <attachment_files_card v-model="reloadFiles" v-if="item['_links'] && item['_links'].files"
                :url="item['_links'].files.href" :title="lang('client.client_files')"></attachment_files_card> -->
            </div>
        </form>
    </div>
</template>

<script>
// @ is an alias to /src
// import vue2Dropzone from 'vue2-dropzone'
import axios from '../../api/axios';
import normalAxios from 'axios'
import api from '../../api/api';
export default {
    name: 'edit',
    template: '#edit-template',
    components: {
        // vueDropzone: vue2Dropzone,
    },
    computed: {
        slownik_client_stage: function () { return this.$store.state.slownik_client_stage },
        slownik_client_group: function () { return this.$store.state.slownik_client_group },
        slownik_client_type: function () { return this.$store.state.slownik_client_type },
        slownik_client_want_to: function () { return this.$store.state.slownik_client_want_to },
        slownik_wojewodztwo: function () { return this.$store.state.slownik_wojewodztwo },
        slownik_user: function () { return this.$store.state.slownik_user },
        slownik_client_economic_form: function () { return this.$store.state.slownik_client_economic_form },
    },
    data: function () {
        return {
            BASE_URL: api.getBaseUrl(),
            dropzoneOptions: {
                url: false
            },
            reloadFiles: false,

            isLoading: true,
            isLoadingGus: false,
            id: 0,
            isEdit: false,
            representative_validation: [],
            header: {
                client_rep_name: '',
            },
            validation: {
                client_comment: '',
                client_economic_form: '',
                client_group: '',
                client_id: "",
                client_krs: '',
                client_name: '',
                client_name_short: '',
                client_nip: '',
                client_regon: '',
                client_stage: '',
                client_status: '',
                client_type: '',
                client_want_to: '',
                client_postcode: '',
                client_city: '',
                client_address: '',
                client_www: '',
                client_wojewodztwo: '',
                user_id: '',
            },
            item: {
                client_id: 0,
                client_comment: '',
                client_economic_form: '',
                client_group: '',
                client_krs: '',
                client_name: '',
                client_name_short: '',
                client_nip: '',
                client_regon: '',
                client_stage: '',
                client_status: '',
                client_type: '',
                client_want_to: {},
                client_postcode: '',
                client_city: '',
                client_address: '',
                client_wojewodztwo: '',
                client_www: '',
                user_id: '',
                representative: [],
            },
            empty_representative_validation: {
                client_rep_name: '',
                client_id: '',
                client_rep_position: '',
                client_rep_phone: '',
                client_rep_email: '',
                client_rep_comment: '',
            },
            empty_representative: {
                client_rep_id: 0,
                client_id: this.id,
                client_rep_name: '',
                client_rep_position: '',
                client_rep_phone: '',
                client_rep_email: '',
                client_rep_comment: '',
            },
            g_client_want_to: {
            },
            g_client_type: {
            },
        }
    },
    methods: {
        lang(x) { return x },
        pobierzPostCode() {
            if (this.item.client_postcode.length < 5 || this.item.client_postcode.length > 6) return
            var vm = this;
            let config = {
                headers: {
                    // 'Referer-Policy':'no-referrer-when-downgrade',
                    'x-api-key': 'did7wr7kg4TDcTRJwslwRxIu',
                }
            }
            normalAxios.get('https://serwer.magico.pl/api/v1/kod/' + vm.item.client_postcode, config).then((response) => {
                return response.data[0].miasto;
            }).then(miasto => {
                // MAGICO_PANEL.alert("success", "Pobrano Miasto z bnazy kodów pocztowych");
                vm.item.client_city = miasto;
            }).catch(error => {
                console.log(error);
                // MAGICO_PANEL.alert("danger", "Nie znaleiono kodu pocztowego");
            });
        },
        pobierzGus(nip) {
            nip = this.item.client_nip;
            this.isLoadingGus = true;
            var vm = this;
            vm.nipInfo = ''
            let config = {
                headers: {
                    // 'Referer-Policy':'no-referrer-when-downgrade',
                    'x-api-key': 'did7wr7kg4TDcTRJwslwRxIu',
                }
            }
            normalAxios.get('https://serwer.magico.pl/api/v1/gus/' + nip, config).then((response) => {
                vm.item.client_name = response.data.data.name;
                vm.item.client_address = response.data.data.street + ' ' + response.data.data.propertyNumber + ' ' + ((response.data.data.apartmentNumber) ? ' / ' + response.data.data.apartmentNumber : '')
                vm.item.client_postcode = response.data.data.zipCode;
                vm.item.client_city = response.data.data.city;
                vm.item.client_regon = response.data.data.regon;
                vm.item.client_wojewodztwo = vm.findProvince(response.data.data.province);
                vm.item.client_krs = response.data.data.krs;
                vm.item.client_www = response.data.data.www;
                vm.validation.nip = false;
                vm.isLoadingGus = false;
                console.log(response.dat);
                // MAGICO_PANEL.alert("success", "Pobrano dane z bazy GUS");
            }, (error) => {
                console.log(error)
                // MAGICO_PANEL.alert("error", "Błąd pobierania informacji z bazy GUS");
                vm.validation.nip = true;
                vm.isLoadingGus = false;
                vm.nipInfo = "Nie znaleziono w bazie GUS"
            })
        },
        findProvince(name) {
            var key = '';
            this.slownik_wojewodztwo.forEach(item => {
                if (item.name == name.toLowerCase()) {
                    key = item.value;
                }
            })
            return key;
        },
        clickPepresentativeAdd() {
            this.item.representative.push(Object.assign({}, this.empty_representative))
            this.representative_validation.push(Object.assign({}, this.empty_representative_validation))
        },
        clickPepresentativeDelete(item) {
            const index = this.item.representative.indexOf(item);
            if (index > -1) {
                this.item.representative.splice(index, 1); // 2nd parameter means remove one item only
                this.representative_validation.splice(index, 1); // 2nd parameter means remove one item only
            }
        },
        changeCheckbox() {
            console.log(this.g_client_want_to);
        },
        convertWantTo(itemsString) {
            var vm = this;
            var ar = itemsString.split('|');
            this.slownik_client_want_to.forEach(i => {
                vm.g_client_want_to[i.value] = false
                ar.forEach(y => {
                    if (y == i.value) vm.g_client_want_to[i.value] = true;
                })
            })
        },
        convertType(itemsString) {
            var vm = this;
            var ar = itemsString.split('|');
            this.slownik_client_type.forEach(i => {
                vm.g_client_type[i.value] = false
                ar.forEach(y => {
                    if (y == i.value) vm.g_client_type[i.value] = true;
                })
            })
        },
        getPost: function () {
            let vm = this;
            vm.isLoading = true;
            axios.get(this.BASE_URL + 'api/v1/client/' + this.id).then(response => {
                //stworzenie pustych walidacji
                this.convertWantTo(response.data.client_want_to);
                this.convertType(response.data.client_type);
                console.log(response.data.representative);
                response.data.representative.forEach(() => {
                    vm.representative_validation.push(Object.assign({}, vm.empty_representative_validation))
                });
                vm.header.client_name = vm.item.client_name;
                vm.item = response.data;
                if (vm.item['_links'].upload) {
                    vm.dropzoneOptions.url = vm.item['_links'].upload.href;
                }
                vm.isLoading = false;
            });
        },
        cancelForm: function () {
            this.$router.push('/client/');
        },
        submitForm: function () {
            var vm = this;
            for (const property in this.validation) {
                this.validation[property] = '';
            }
            for (const tablica in this.representative_validation) {
                for (const property in this.representative_validation[tablica]) {
                    this.representative_validation[tablica][property] = '';
                }
            }
            vm.item.client_want_to = '';
            vm.slownik_client_want_to.forEach(i => {
                if (vm.g_client_want_to[i.value]) vm.item.client_want_to = vm.item.client_want_to + i.value + '|';
            });
            vm.item.client_type = '';
            vm.slownik_client_type.forEach(i => {
                if (vm.g_client_type[i.value]) vm.item.client_type = vm.item.client_type + i.value + '|';
            });
            if (this.isEdit > 0) {
                this.updatePost();
            } else {
                this.createPost();
            }
            console.log(this.item);
        },
        handleAxiosError: function (error) {
            if (error.response.data.messages) {
                for (const property in error.response.data.messages) {
                    this.validation[property] = error.response.data.messages[property];
                }
                if (error.response.data.messages.representative) {
                    // x=0;
                    for (const tablica in error.response.data.messages.representative) {
                        console.log('tablica', tablica, error.response.data.messages.representative);
                        for (const property in error.response.data.messages.representative[tablica]) {
                            this.representative_validation[tablica][property] = error.response.data.messages.representative[tablica][property];
                        }
                        // x++;
                    }
                }
            } else console.log(error);
        },
        createPost: function () {
            var vm = this;
            axios.post(this.BASE_URL + 'api/v1/client', this.item).then(() => {
                this.$router.push('/client');
            }).catch(error => {
                vm.handleAxiosError(error);
            });
        },
        updatePost: function () {
            axios.put(this.BASE_URL + 'api/v1/client/' + this.id, this.item).then(() => {
                this.$router.push('/client');
            }).catch(this.handleAxiosError);
        },
        sendingEvent: function () {
            this.lockSave = true;
        },
        uploadSuccess: function () {
            // MAGICO_PANEL.alert('success', 'pliki wgrane prawidłowo');
            this.lockSave = false;
            this.reloadFiles = true;
            this.$refs.myVueDropzone.removeAllFiles();
        },
    },
    created() {
        this.id = this.$route.params.id;
        this.isEdit = (this.id > 0) ? 1 : 0;
        if (this.isEdit) {
            this.getPost()
        } else {
            this.isLoading = false;
        }
    }
}
</script>
